.pagination {
  display: flex;
  flex-wrap: wrap;
  gap: 0.5rem;

  &__link {
    border: 1px solid;
    // width: 2rem;
    // height: 2rem;
    width: 4rem;
    height: 4rem;
    border-radius: 50%;
    display: flex;
    align-items: center;
    justify-content: center;

    color: #8898aa;
    background-color: #fff;
    border: 1px solid #dee2e6;
    &:hover {
      color: #8898aa;
      text-decoration: none;
      background-color: #dee2e6;
      border-color: #dee2e6;
    }
    &--disabled a {
      cursor: default;
      color: #8898aa;
      background-color: #fff;
      border: 1px solid #dee2e6;
    }

    &--active a {
      background-color: #5e72e4;
      color: #fff;
    }

    @media (max-width: 767.99px) {
      width: 2rem !important;
      height: 2rem !important;
      font-size: 10px !important;
    }
  }
}
