@import "./signin.scss";
@import "./header.scss";
.globalhead {
  cursor: pointer;
}

.main-content {
  background: #eaeef4 !important;
}

.industry-table-body {
  width: 100%;
  max-height: 300px !important;
  overflow-y: auto;
}

.brand-optin-checkbox {
  width: 16px;
  height: 16px;
}
